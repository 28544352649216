<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              User Detail
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="6"
        cols="12"
      >
        <b-button
          v-if="canViewThisAction('change-password', 'User')"
          variant="primary"
          class="mr-1"
          :to="{ name: 'directory-users-change-password', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="LockIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Change Password</span>
        </b-button>
        <b-button
          v-if="canViewThisAction('update', 'User')"
          variant="primary"
          :to="{ name: 'directory-users-edit', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit User</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      no-body
      class="mb-3 p-2"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Name
            </b-th>
            <b-td>{{ user.name }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Email
            </b-th>
            <b-td>{{ user.email }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Position
            </b-th>
            <b-td>{{ user.position }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Department
            </b-th>
            <b-td>
              <div class="">
                <p
                  v-for="(department, key) in user.department"
                  :key="key"
                  class="mb-0"
                >
                  {{ department.name }}
                </p>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Roles
            </b-th>
            <b-td>
              <!-- <strong
                v-for="(role, key) in user.roles"
                :key="key"
              >
                {{ role.role }}<span v-if="key + 1 != user.roles.length">, </span>
              </strong> -->
              <b-button
                v-for="(role, key) in user.roles"
                :key="key"
                :title="role.role"
                variant="outline-danger"
                class="small-spacing-button"
              >
                {{ role.role }}
              </b-button>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Status
            </b-th>
            <b-td
              class="text-capitalize"
            >
              <span :class="resolveStatus(user.status)">{{ user.status }}</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Created On
            </b-th>
            <b-td>{{ dateFormatWithTime(user.createdAt) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Last Updated At
            </b-th>
            <b-td>{{ dateFormatWithTime(user.updatedAt) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  data() {
    return {
      canViewThisAction,
      user: {},
    }
  },
  created() {
    this.$http.get(`directory/users/${this.$route.params.id}/show`)
      .then(response => {
        this.user = response.data ?? {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolveStatus(status) {
      if (status === 'active') return 'text-success'
      if (status === 'inactive') return 'text-danger'
      return 'text-primary'
    },
  },
}
</script>
